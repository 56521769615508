import Repository from "@/api/Repository";

class DispatchRepository {

    getDispatchCalls(id=null) {
        let qs = "";
        if (id !== null) {
            qs = "/" + id;
        }
        return Repository.get('dispatch-calls' + qs);
    };

    createDispatchCall(data){
       return Repository.post('dispatch-calls',data);
    };

    updateDispatchCall(data,id){
        return Repository.put('dispatch-calls/'+id,data);
    };

    deleteDispatchCall(id){
        return Repository.delete('dispatch-calls/'+id);
    };

    /**
     *
     * @param type 'attach'|'detach'
     * @param id
     * @param units Array
     * @returns {Promise<AxiosResponse<any>>}
     */
    changeUnitsCall(type,id, units){
        let url='';
        if(type==='attach'){
            url='attachUnits';
        } else if(type==='detach'){
            url='detachUnits';
        }

        units={
            'units':units
        };

        return Repository.post('dispatch-calls/'+id+"/"+url, units);
    }

    signal100update(text=null){
        let data={};
        if(text !== null){
            data.text=text;
        }
        return Repository.post('dispatch-calls/signal100',data);
    }
}

export default new DispatchRepository();
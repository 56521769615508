<template>

  <b-modal no-close-on-backdrop id="unit-identifier-form-modal" size="lg" title="Create Identifier">

    <b-form-group
        label="Identifier"
        label-for="identifier"
    >
      <b-form-input
          id="identifier"
          v-model="form.identifier"
          type="text"
          placeholder="Identifier (i.e.: D-30)"
          required
      ></b-form-input>
    </b-form-group>

    <b-form-group
        label="Name"
        label-for="name"
    >
      <b-form-input
          id="name"
          v-model="form.name"
          type="text"
          required
      ></b-form-input>
    </b-form-group>

    <b-form-group
        label="Rank"
        label-for="rank"
    >
      <b-form-input
          id="rank"
          v-model="form.rank"
          type="text"
          required
      ></b-form-input>
    </b-form-group>


    <b-form-group
        label="Department"
        label-for="department"
    >
      <select id="departments" v-model="form.agency_id" class="form-control">
        <option v-for="dept in departments" :value="dept.id">{{ dept.full_name }}</option>
      </select>
    </b-form-group>

    <b-form-group
        label="AOP"
        label-for="aop"
    >
      <b-form-input
          id="aop"
          v-model="form.aop"
          type="text"
      ></b-form-input>
    </b-form-group>

    <b-form-group
        label="Sub-Division"
        label-for="subdivision"
    >
      <b-form-input
          id="subdivision"
          v-model="form.subdivision"
          type="text"
      ></b-form-input>
    </b-form-group>

    <b-alert v-model="showErrors" variant="danger">
      <ul v-for="error in errors">
        <li v-for="err in error">{{ err }}</li>
      </ul>
    </b-alert>

    <template #modal-footer="{ok,cancel}">
      <b-button  variant="primary" @click="createNewUnit()" v-if="unit===null">
        Create
      </b-button>
      <b-button  variant="primary" @click="saveUnit()" v-if="unit!==null">
        Save
      </b-button>
      <b-button  variant="danger" @click="$root.$emit('bv::hide::modal', 'unit-identifier-form-modal')">
        Close
      </b-button>
    </template>

  </b-modal>

</template>

<script>
import DataRepository from "@/api/DataRepository";
import LeoRepository from "@/api/LeoRepository";

export default {
  name: "UnitForm",
  props: ['unit'],
  data(){
    return {
      form:{
        identifier:null,
        name: null,
        rank: null,
        aop: null,
        subdivision: null,
        agency_id: null,
      },
      departments: {},
      errors: null
    }
  },
  computed: {
    showErrors(){
      return this.errors !== null;
    }
  },
  created(){
    DataRepository.getDeparments().then(response => {
      this.departments=response.data.data;
    });
  },
  methods: {
    createNewUnit(){
      LeoRepository.createNewUnit(this.form).then(response => {
        this.$emit("updateUnitsList");
        this.$root.$emit('bv::hide::modal', 'unit-identifier-form-modal');
        this.errors=null;
      }).catch(error => {
        this.errors=error.response.data.errors;
      });
    },
    saveUnit(){
      LeoRepository.updateUnit(this.unit.id,this.form).then(response => {
        this.$root.$emit('bv::hide::modal', 'unit-identifier-form-modal');
        this.$emit("updateUnitsList");
        this.errors=null;
      }).catch(error => {
        this.errors=error.response.data.errors;
      });
    }
  },
  watch: {
    unit: function(unit){
      if(unit !== null){
        this.form.identifier=unit.identifier;
        this.form.name=unit.name;
        this.form.rank=unit.rank;
        this.form.aop=unit.aop;
        this.form.subdivision=unit.subdivision;
        this.form.agency_id=unit.agency_id;
      } else {
        this.form.identifier=null;
        this.form.name=null;
        this.form.rank=null;
        this.form.aop=null;
        this.form.subdivision=null;
        this.form.agency_id=null;
      }

    }
  }
}
</script>

<style scoped>

</style>
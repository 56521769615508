<template>
  <div>
    <div class="table-responsive table-wrapper-scroll-y my-custom-scrollbar">
      <table class="table table-striped table-dark table-sm table-hover table-bg-darker">
        <thead class="thead-light">
        <tr>
          <th scope="col">Origin</th>
          <th scope="col">10 Code</th>
          <th scope="col">Title</th>
          <th scope="col">Address</th>
          <th scope="col">Units</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(call,index) in calls" @click="toggleCallDropdown($event,index)" @drop="dropAttach($event, call)" @dragover.prevent
            @dragenter.prevent>
          <td>{{ origins[call.call_origin] }}</td>
          <td>{{ formatCode(call.code.code) }}</td>
          <td>{{ call.title }}</td>
          <td>{{ call.address }} <template v-if="call.postal!==null">({{call.postal}})</template></td>
          <td>
            <b-badge variant="light" class="mr-1" v-for="unit in call.units" :key="unit.id"  @click="detachUnit(unit,call)">{{ unit.identifier }}</b-badge>
            <ContextMenu ref="callContext" :data-index="index">
              <b-dropdown-item href="#" v-on:click="openEditorCall(call)">Open in editor</b-dropdown-item>
              <b-dropdown-item href="#" v-on:click="selectCallToDispatch(index)">Open notes</b-dropdown-item>
              <b-dropdown-item href="#" v-on:click="closeCall(call)">Close call</b-dropdown-item>
            </ContextMenu>
          </td>
        </tr>
        </tbody>
      </table>
      <ManageCall ref="editCall"></ManageCall>
    </div>


    <b-form-row v-if="call_dispatched!==null">
      <b-col cols="12">
        <b-textarea v-model="calls[call_dispatched].notes" placeholder="Notes" rows="3" v-html="true" ></b-textarea>
      </b-col>
    </b-form-row>

    <b-form-row v-if="call_dispatched!==null">
      <b-col cols="8">
        <b-form-input type="text" v-model="note" @keyup.enter.native="addNote"></b-form-input>
      </b-col>
      <b-col cols="2">
        <b-button variant="success" class="form-control" @click="addNote">Add note</b-button>
      </b-col>
      <b-col cols="2">
        <b-button variant="danger" class="form-control" @click="closeNotes">Close</b-button>
      </b-col>
    </b-form-row>
  </div>

</template>

<script>
import DispatchRepository from "@/api/DispatchRepository";
import ManageCall from "@/components/Partials/Dispatch/ManageCall";
import ContextMenu from "@/components/Partials/Dispatch/ContextMenu";

export default {
  name: "DispatchCallList",
  components: {ContextMenu, ManageCall},
  data(){
    return {
      calls:null,
      origins:{
        'caller':'Caller',
        'radio':'Radio'
      },
      call_dispatched: null,
      note:null
    }
  },
  mounted() {
    this.updateDispatchCalls();
  },
  methods:{
    updateDispatchCalls(){
      DispatchRepository.getDispatchCalls().then(response => {
        this.calls=response.data.data;
      });
    },

    formatCode(code){
      code=code.split(":");
      return code[0].trim();
    },

    openEditorCall(call){
      this.$refs['editCall'].openCallEdit(call);
    },

    toggleCallDropdown(event,index){

      const dropdown=this.$refs['callContext'].find(
          el => el.$attrs['data-index'] === index
      );


      this.$refs['callContext'].forEach(e=>{
        if(e.$attrs['data-index'] !== index) e.close();
      });

      dropdown.toggle(event);
    },

    dropAttach(evt,call){
      const unit=evt.dataTransfer.getData('unit_id');

      if(unit !== null){
        DispatchRepository.changeUnitsCall('attach',call.id,[unit]);
      }
    },

    closeCall(call){
      let data=call;
      data.call_status='closed';
      DispatchRepository.updateDispatchCall(data,call.id);
    },

    addNote(){
      let fnote='';
      if(this.calls[this.call_dispatched].notes !== null){
        fnote=this.calls[this.call_dispatched].notes;
      }

      let note=this.moment().format('Y-MM-DD HH:mm:ss') + " | " + this.note + "\n";
      this.calls[this.call_dispatched].notes=note+fnote;
      DispatchRepository.updateDispatchCall(this.calls[this.call_dispatched], this.calls[this.call_dispatched].id);
      this.note=null;
    },

    selectCallToDispatch(index){
      this.call_dispatched=index;
      this.note=null;
    },

    closeNotes(){
      this.call_dispatched=null;
    },
  },
  sockets: {
    incomingDispatchCall: function(){
      this.updateDispatchCalls();
    },
    updateDispatchCall: function(){
      this.updateDispatchCalls();
    }
  }
}
</script>

<style scoped>

.table-bg-darker {
  background-color: #212529;
}

</style>
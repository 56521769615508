<template>
  <div>


    <div class="row">
      <div class="col-md-4">
        <h5 class="text-white" v-if="dispatch===1">Active Officers</h5>
        <h5 class="text-white" v-if="dispatch!==1">Available Identifiers</h5>
      </div>
      <div class="col-md-8">
        <b-button v-b-modal.filter-modal variant="primary" class="float-right" size="sm">Filter</b-button>
      </div>
    </div>

    <b-modal no-close-on-backdrop id="filter-modal" size="lg" title="Filter Units" ok-title="Filter">
      <div class="row">
        <div class="col-md-4">
          <b-form-group
              label="Department(s)"
              label-for="department"
          >
            <select id="departments" v-model="filters.departments" class="form-control" multiple>
              <option v-for="dept in departments" :value="dept">{{ dept.full_name }}</option>
            </select>
          </b-form-group>
        </div>
      </div>

      <template #modal-footer="{ ok, cancel, hide }">
        <b-button size="sm" variant="primary" @click="ok()">
          OK
        </b-button>
        <b-button size="sm" variant="secondary" @click="cancel()">
          Cancel
        </b-button>
        <!-- Button with custom close trigger value -->
        <b-button size="sm" variant="danger" @click="resetFilters">
          Reset
        </b-button>
      </template>
    </b-modal>

    <div class="table-responsive table-wrapper-scroll-y my-custom-scrollbar">

      <div class="my-custom-scrollbar table-wrapper-scroll-y">
        <table class="table-hover table table-striped table-dark table-sm table-bg-darker">
          <thead class="thead-light">
          <tr>
            <th scope="col">Identifier</th>
            <th scope="col">Name</th>
            <th scope="col">AOP</th>
            <th scope="col">Department</th>
            <th scope="col">Status</th>
            <th scope="col" v-if="button_name!==null && typeof button_name !== 'undefined' && dispatch!==1">&nbsp;</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(unit,index) in units" :draggable="(unit.call_id===null ? true : false)" @dragstart="startDraggingToAttach($event, unit)" @click="toggleUnitDropdown($event,index)">
            <td>{{  unit.identifier }}</td>
            <td>{{  unit.name }}</td>
            <td>{{  unit.aop }}</td>
            <td>{{  unit.agency.short_name }}</td>
            <td :style="{color: unit.current_status.color, fontWeight: 'bolder'}">{{  unit.current_status.code }} <b-badge variant="warning" v-if="unit.call_id">On call</b-badge></td>
            <td  v-if="button_name!==null && typeof button_name !== 'undefined' && dispatch!==1">
              <button class="btn btn-primary" v-on:click="onClickUnitButton(unit)">{{ button_name }}</button>
              <button class="btn btn-primary" v-if="(doesUserHavePermission('unit_edit') || unit.created_by === user.id)" v-on:click="editUnitForm(unit)">Edit</button>
            </td>
            <div v-if="noctx !== true">
              <ContextMenu ref="unitContext" :data-index="index">
                <b-dropdown-item href="#" v-for="status in statuses" v-if="statuses!==null" :key="status.id" @click="updateUnitStatus(unit,status)" ><span :style="{color: status.color}">{{ status.code }}</span></b-dropdown-item>
                <hr>
                <b-dropdown-item href="#" v-if="(doesUserHavePermission('unit_edit') || unit.created_by === user.id)" v-on:click="editUnitForm(unit)">Edit unit</b-dropdown-item>
                <b-dropdown-item href="#" v-if="unit.call_id" @click="detachFromCurrentCall(unit)">Detach from current</b-dropdown-item>
              </ContextMenu>
            </div>
          </tr>
          </tbody>
          <UnitForm :unit="editUnit" @updateUnitsList="updateUnitList"></UnitForm>
        </table>
      </div>
    </div>
  </div>




</template>

<script>
import LeoRepository from "@/api/LeoRepository";
import UnitForm from "@/components/Partials/LEO/Units/UnitForm";
import DispatchRepository from "@/api/DispatchRepository";
import ContextMenu from "@/components/Partials/Dispatch/ContextMenu";
import DataRepository from "@/api/DataRepository";

export default {
  name: "UnitList",
  components: {ContextMenu, UnitForm},
  props: [ 'dispatch', 'button_name', 'noctx'],
  data(){
    return {
      unitList: null,
      loading: true,
      editUnit: null,
      statuses:null,
      filters:{
        departments:null
      },
      showFilter:false,
      departments: null
    }
  },
  computed: {
    units(){

      if(this.unitList === null) return null;

      let ul=this.unitList;

      if(this.filters.departments !== null){
        ul= this.unitList.filter( unit => {
          return this.filters.departments.some(el=>unit.agency.short_name.includes(el.short_name));
        });
      }

      return ul;
    },
    user() {
      return this.$store.state.auth.user;
    },
  },
  created(){
    this.updateUnitList();
    DataRepository.getUnitStatus().then(response => {
      this.statuses=response.data.data;
    });

    DataRepository.getDeparments().then(response => {
      this.departments=response.data.data;
    })
  },
  methods: {
    onClickUnitButton: function(unit){
      this.$emit("clickedUnit", unit);
    },
    updateUnitList: function(){
      let dis= this.dispatch === 1 ? 1 : null;
      LeoRepository.getUnits(dis).then(response => {
        this.unitList=response.data.data;
        this.loading=false;
      });
    },
    editUnitForm(unit){
      this.editUnit=unit;
      setTimeout(() => this.$root.$emit('bv::show::modal', 'unit-identifier-form-modal'), 100);
    },
    setCreateMode() {
      this.editUnit=null;
      setTimeout(() => this.$root.$emit('bv::show::modal', 'unit-identifier-form-modal'), 100);
    },
    startDraggingToAttach(evt,unit){
      evt.dataTransfer.dropEffect = 'move'
      evt.dataTransfer.effectAllowed = 'move'
      evt.dataTransfer.setData('unit_id', unit.id);
    },
    detachFromCurrentCall(unit){
      DispatchRepository.changeUnitsCall('detach',unit.call_id,[unit.id]);
    },
    updateUnitStatus(unit,status){
      LeoRepository.updateUnitStatus(unit.id,status);
    },
    toggleUnitDropdown(event,index){

      const dropdown=this.$refs['unitContext'].find(
          el => el.$attrs['data-index'] === index
      );


      this.$refs['unitContext'].forEach(e=>{
        if(e.$attrs['data-index'] !== index) e.close();
      });

      dropdown.toggle(event);
    },

    resetFilters(){
      this.filters.departments=null;
    }
  },
  sockets: {
    updateUnitsList: function(data){
      if(data.length>0 || typeof data.id !== "undefined"){
        let d = this.unitList.findIndex(item => item.id === data.id);
        if(d===-1){
          if(data.current_status_id!==2 && (this.dispatch===1 || data.created_by_id === this.user.id) ) this.unitList.push(data);
        } else {

          if(data.current_status_id===2){
            this.unitList.splice(d,1);
          } else {
            this.$set(this.unitList,d,data);
          }
        }
      }


    }
  }
}
</script>

<style scoped>
.my-custom-scrollbar {
  max-width: 100%;
  max-height: 300px;
  overflow: auto;
}
.table-wrapper-scroll-y {
  display: block;
}

.table-bg-darker {
  background-color: #212529;
}
</style>
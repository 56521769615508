<template>
  <div>
    <b-button class="btn btn-warning" v-if="this.unit === null" v-b-modal.unit-identifier-modal>Select Identifier</b-button>

    <div v-if="this.unit !== null" >
      <p class="text-center text-white-50" v-b-modal.unit-identifier-modal>
        {{ unit.identifier }}
        <br>{{ unit.rank }} {{ unit.name }} - {{ unit.agency.short_name }}
      </p>
    </div>

    <b-modal no-close-on-backdrop id="unit-identifier-modal" size="lg" title="Select Identifier" body-bg-variant="dark" header-bg-variant="dark" header-text-variant="white" footer-bg-variant="dark">
      <UnitList :noctx="noctx" @clickedUnit="onUnitClicked" button_name="Select" ref="unitList" ></UnitList>

      <template #modal-footer="{ok,cancel}">
        <b-button variant="primary" v-on:click="createNewUnit()">
          Create
        </b-button>
        <b-button  variant="danger" @click="$root.$emit('bv::hide::modal', 'unit-identifier-modal')">
          Close
        </b-button>
      </template>

    </b-modal>
  </div>
</template>

<script>
import UnitList from "@/components/Partials/LEO/Units/UnitList";
import LeoRepository from "@/api/LeoRepository";
import UnitForm from "@/components/Partials/LEO/Units/UnitForm";
export default {
  name: "UnitIdentifier",
  components: {UnitForm, UnitList},
  props: ['noctx'],
  data() {
    return {
      editUnit: null,
      createUnit: false
    }
  },
  computed: {
    unit() {
      return this.$store.state.auth.leoUnit;
    }
  },
  created(){
    setInterval(() => {

      if(this.unit !== null){
        LeoRepository.updateUnitStatus(this.unit.id,this.unit.current_status).then(response => {
        });
      }

    }, 300000);
  },
  methods: {
    onUnitClicked(unit){
      this.$store.dispatch("auth/leoUnitSet", unit);
      this.$root.$emit('bv::hide::modal', 'unit-identifier-modal');
    },
    createNewUnit(){
      this.$refs.unitList.setCreateMode();
    }
  }
}
</script>

<style scoped>
#unit-identifier-modal {
  z-index:9999;
}
</style>
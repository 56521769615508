import LeoRepository from "@/api/LeoRepository";

const MyPlugin = {
  install(Vue, options) {

    Vue.prototype.doesUserHavePermission = function (permission) {
      let storeUserInfo=localStorage.getItem('userinfo');
      if(storeUserInfo === null) return false;

      let permissions=JSON.parse(storeUserInfo).permissions;

      if(permissions.includes(permission)){
        return true;
      } else {
        return false;
      }

    };

  	Vue.mixin({
      mounted() {
      }
    })
  },
}

export default MyPlugin

<template>
  <div>
    <b-modal no-close-on-backdrop id="edit-form-modal" size="lg" title="Create Identifier">

      <b-form-group
          label="Identifier"
          label-for="identifier"
      >
        <b-form-input
            id="identifier"
            v-model="form.identifier"
            type="text"
            placeholder="Identifier (i.e.: D-30)"
            required
        ></b-form-input>
      </b-form-group>

      <b-form-group
          label="Name"
          label-for="name"
      >
        <b-form-input
            id="name"
            v-model="form.name"
            type="text"
            required
        ></b-form-input>
      </b-form-group>

      <b-form-group
          label="Rank"
          label-for="rank"
      >
        <b-form-input
            id="rank"
            v-model="form.rank"
            type="text"
            required
        ></b-form-input>
      </b-form-group>


      <b-form-group
          label="Department"
          label-for="department"
      >
        <select id="departments" v-model="form.agency_id" class="form-control">
          <option v-for="dept in departments" :value="dept.id">{{ dept.full_name }}</option>
        </select>
      </b-form-group>

      <b-form-group
          label="AOP"
          label-for="aop"
      >
        <b-form-input
            id="aop"
            v-model="form.aop"
            type="text"
        ></b-form-input>
      </b-form-group>

      <b-form-group
          label="Sub-Division"
          label-for="subdivision"
      >
        <b-form-input
            id="subdivision"
            v-model="form.subdivision"
            type="text"
        ></b-form-input>
      </b-form-group>

    </b-modal>
  </div>
</template>

<script>
import DataRepository from "@/api/DataRepository";

export default {
  name: "EditUnit",
  data() {
    return {
      form: {
        identifier: null,
        name: null,
        rank: null,
        aop: null,
        subdivision: null,
        agency_id: null,
      },
      departments: {},
      errors: null,
      computed: {
        showErrors() {
          return this.errors !== null;
        }
      },
      created() {
        DataRepository.getDeparments().then(response => {
          this.departments = response.data.data;
        });
      },
    }
  }
}
</script>

<style scoped>

</style>
<template>

  <b-modal no-close-on-backdrop id="civilian-lookup-modal" size="xl" title="Civilian Name" ref="civilian-lookup-modal">
    <b-form-row>
      <b-col cols="8">

        <b-row>
          <b-col cols="8">
            <div class="fw-bold font-weight-bolder dd-title">Full Name</div>
            {{ civilian.name }}
            <div class="fw-bold font-weight-bolder dd-title">Date of Birth</div>
            {{ civilian.dob }} ({{ civilian.age }})
            <div class="fw-bold font-weight-bolder dd-title">Hair Color</div>
            {{ civilian.hair_color }}
            <div class="fw-bold font-weight-bolder dd-title">Address</div>
            {{ civilian.address }}
            <div class="fw-bold font-weight-bolder dd-title">Height</div>
            {{ civilian.height }}
          </b-col>
          <b-col>
            <div class="fw-bold font-weight-bolder dd-title">Gender</div>
            {{ civilian.gender.name }}
            <div class="fw-bold font-weight-bolder dd-title">Ethnicity</div>
            {{ civilian.ethnicity.name }}
            <div class="fw-bold font-weight-bolder dd-title">Eye Color</div>
            {{ civilian.eye_color }}
            <div class="fw-bold font-weight-bolder dd-title">Postal</div>
            {{ civilian.postal }}
            <div class="fw-bold font-weight-bolder dd-title">Weight</div>
            {{ civilian.weight }}
          </b-col>
        </b-row>
      </b-col>
      <b-col>
        <img :src="picture.url" class="profile-picture" v-if="picture!==null">
      </b-col>
    </b-form-row>



    <b-row>
      <b-col cols="8">
        <div class="fw-bold font-weight-bolder dd-title">Occupation</div>
        {{ civilian.occupation }}
      </b-col>
      <b-col cols="4">
        <div class="fw-bold font-weight-bolder dd-title">Phone Number</div>
        {{ civilian.phone_number }}
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <div class="fw-bold font-weight-bolder dd-title">Medical Records</div>
        <p class="medical-records">{{ civilian.medical_record }}</p>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <div>
          <div class="fw-bold font-weight-bolder dd-title">Notes</div>
          <b-textarea v-model="edit.officers_notes" row="2"></b-textarea>
        </div>
      </b-col>

    </b-row>

    <template #modal-footer="{ ok, cancel }">

      <div class="w-100">
        <div class="float-left">
          <template v-if="!fire">
            <b-button variant="primary" v-b-modal.arrest-report-form-modal v-if="doesUserHavePermission('arrest_report_create')">
              <b-icon icon="file-earmark-lock"></b-icon> Arrest Report
            </b-button>
            <b-button variant="primary" v-b-modal.warrant-form-modal v-if="doesUserHavePermission('warrant_create')">
              <b-icon icon="file-earmark-lock"></b-icon> Warrant
            </b-button>
            <b-button variant="primary" v-b-modal.createBOLO-form-modal v-if="doesUserHavePermission('bolo_create')">
              <b-icon icon="search"></b-icon> BOLO
            </b-button>
            <b-button variant="primary" v-b-modal.citation-form-modal v-if="doesUserHavePermission('leo_citation_create')">
              <b-icon icon="file-ruled"></b-icon> Citation
            </b-button>
          </template>
          <template v-else>
            <b-button variant="primary" v-b-modal.medical-record-form-modal v-if="doesUserHavePermission('medical_record_create')">
              <b-icon icon="file-earmark-lock"></b-icon> Medial Record
            </b-button>
          </template>

        </div>

        <div class="float-right">
          <b-button variant="success" @click="saveOfficersNotes()" v-if="!fire">
            Save
          </b-button>
          <b-button variant="danger" @click="cancel()">
            Close
          </b-button>
        </div>

      </div>

    </template>

    <ArrestReport :civilian="civilian" v-if="civilian!==null && !fire"></ArrestReport>
    <Warrant :civilian="civilian" v-if="civilian!==null && !fire"></Warrant>
    <CreateBOLO :civilian="civilian" v-if="civilian!==null && !fire"></CreateBOLO>
    <Citation :civilian="civilian" v-if="civilian!==null && !fire"></Citation>

    <MedicalRecord :civilian="civilian"></MedicalRecord>

  </b-modal>

</template>

<script>
import CitizenRepository from "@/api/CitizenRepository";
import ArrestReport from "@/components/Partials/LEO/Reports/ArrestReport";
import Warrant from "@/components/Partials/LEO/Reports/Warrant";
import CreateBOLO from "@/components/Partials/LEO/Reports/CreateBOLO";
import Citation from "@/components/Partials/LEO/Reports/Citation";
import LeoRepository from "@/api/LeoRepository";
import MedicalRecord from "@/components/Partials/LEO/Reports/MedicalRecord";

export default {
  name: "CivilianShow",
  components: {MedicalRecord, Citation, CreateBOLO, Warrant, ArrestReport},
  props: ['civilian','fire'],
  data(){
    return {
      edit:{
        officers_notes:this.civilian.officers_notes
      },
      picture:null
    }
  },
  mounted() {
    CitizenRepository.getPicture(this.$props.civilian.id).then(response => {
      this.picture=response.data.data;
    });
  },
  methods:{
    saveOfficersNotes(){
      if(this.edit.officers_notes!==this.civilian.officers_notes) LeoRepository.updateOfficersNotes(this.civilian.id,this.edit.officers_notes);
      this.$refs['civilian-lookup-modal'].hide();
    }
  }
}
</script>

<style scoped>
  .profile-picture {
    max-height: 300px;
    max-width: 100%;
    float: right;
    vertical-align: top;
  }

  .dd-title:not(:first-child){
    margin-top: 15px;
  }

  .medical-records {
    white-space: break-spaces;
  }
</style>
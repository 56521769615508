import Vue from 'vue'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import App from './App.vue'
import router from './router'
import store from './store'

import MyPlugin from './plugins/my-plugin'

import io from 'socket.io-client';
import VueSocketIO from 'vue-socket.io';

import vSelect from 'vue-select'

export const SocketInstance = io(process.env.VUE_APP_SOCKET_URL);


import moment from 'moment-timezone'

moment.tz.setDefault('America/New_York')
Vue.prototype.moment = moment

Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.use(MyPlugin)
Vue.use(new VueSocketIO({
      debug: false,
      connection: SocketInstance, //options object is Optional
      vuex: {
        store,
        actionPrefix: "SOCKET_",
        mutationPrefix: "SOCKET_"
      }
    })
);
Vue.component('v-select', vSelect);

Vue.config.productionTip = false
Vue.prototype.$log = console.log
Vue.prototype.$baseDomain=process.env.VUE_APP_API_URL;
Vue.prototype.$cadVersion=process.env.VUE_APP_CAD_VERSION;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')

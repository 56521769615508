<template>
  <div class="pb-5">
    <div class="container">
      <div class="row mt-4">
        <div class="col">
          <NewCitizen  v-if="citizenInfo===null" ref="newCitizen" @update="updateCitizenList"></NewCitizen>
          <router-link :to="{name:'citizen'}" class="btn btn-info btn-block" v-if="citizenInfo!==null">Switch Civilians</router-link>
        </div>
      </div>
      <div class="row" v-if="citizenInfo!==null">
        <div class="col padding-right">
          <NewLicense :civilian="citizenInfo" @updateCivilianData="getCitizenInfo" ref="newLicenseForm"></NewLicense>
        </div>
        <div class="col padding-both">
          <NewWeapon :civilian="citizenInfo" @updateCivilianData="getCitizenInfo" ref="newWeaponForm"></NewWeapon>
        </div>
        <div class="col padding-both">
          <NewVehicle :civilian="citizenInfo" @updateCivilianData="getCitizenInfo" ref="newVehicleForm"></NewVehicle>
        </div>
        <div class="col padding-left">
          <b-button block variant="warning" v-b-modal.notepad>Notepad</b-button>
        </div>
        <Notepad type="civilian"></Notepad>
      </div>
    </div>
    <div class="container">
      <div class="row mt-3">
        <div class="col-12">
          <div v-if="citizenInfo === null">
            <CivilianList @edit="editCitizen" ref="citizenList"></CivilianList>
          </div>

          <div v-if="citizenInfo!==null">
            <CivilianShow :civilian="citizenInfo"></CivilianShow>
          </div>

        </div>
      </div>

      <div class="row" v-if="citizenInfo!==null">
        <div class="col-md-3">
          <b-card bg-variant="grey-600" header="Licenses" class="text-center">
            <b-card-body class="text-left">
              <dl>
                <template v-for="license in citizenInfo.civilian_civilian_licenses">
                  <dt @click="openLicenseFormUpdate(license)" >{{ license.license.name }}</dt>
                  <dd class="text-capitalize" @click="openLicenseFormUpdate(license)" >{{ license.status }}</dd>
                </template>
              </dl>
            </b-card-body>
          </b-card>
        </div>
        <div class="col-md-3">
          <b-card bg-variant="grey-600" header="Firearms" class="text-center">
            <b-card-body class="text-left">
              <dl>
                <template v-for="weapon in citizenInfo.owner_civilian_weapons">
                  <dt @click="openWeaponFormUpdate(weapon)">{{ weapon.weapon_serial_number }}</dt>
                  <dd @click="openWeaponFormUpdate(weapon)">{{ weapon.weapon_model }}<br>{{ weapon.weapon_type }}</dd>
                </template>
              </dl>
            </b-card-body>
          </b-card>
        </div>
        <div class="col-md-6">
          <b-card bg-variant="grey-600" header="Vehicles" class="text-center">
            <b-card-body class="text-left">
              <div v-for="vehicle in citizenInfo.owner_civilian_vehicles">
                <div class="row" @click="openVehicleFormUpdate(vehicle)">
                  <div class="col-md-12">Plate: <b>{{ vehicle.plate }}</b></div>
                  <div class="col-md-6">{{ vehicle.make }}</div>
                  <div class="col-md-6">{{ vehicle.model }}</div>
                  <div class="col-md-6">{{ vehicle.color }}</div>
                  <div class="col-md-6">{{ vehicle.year }}</div>
                </div>
                <hr>
              </div>

            </b-card-body>
          </b-card>
        </div>
      </div>

    </div>

  </div>

</template>

<script>
import Notepad from "@/components/Partials/Notepad";
import NewCitizen from "@/components/Partials/Citizen/NewCitizen";
import NewLicense from "@/components/Partials/Citizen/NewLicense";
import NewVehicle from "@/components/Partials/Citizen/NewVehicle";
import NewWeapon from "@/components/Partials/Citizen/NewWeapon";
import CitizenRepository from "@/api/CitizenRepository";
import CivilianList from "@/components/Partials/Citizen/CivilianList";
import CivilianShow from "@/components/Partials/Citizen/CivilianShow";

export default {
  name: "Citizen",
  components: {CivilianShow, CivilianList, NewWeapon, NewVehicle, NewLicense, NewCitizen, Notepad},
  props: ['citizen'],
  data(){
    return {
      citizenInfo:null,
      selectLicense:null,
    }
  },
  created(){
    this.citizenInfo=null;
    this.getCitizenInfo();
  },
  methods:{
    getCitizenInfo(){
      if(typeof this.citizen !== "undefined"){
        CitizenRepository.getCitizen(this.$props.citizen).then(response => {
          this.citizenInfo=response.data.data;
        });
      }
    },
    openLicenseFormUpdate(license){
      this.$refs['newLicenseForm'].openToUpdate(license);
    },
    openWeaponFormUpdate(weapon){
      this.$refs['newWeaponForm'].openUpdateWeapon(weapon);
    },
    openVehicleFormUpdate(vehicle){
      this.$refs['newVehicleForm'].openVehicleFormUpdate(vehicle);
    },

    editCitizen(citizen){
      this.$refs['newCitizen'].openCivilianEdit(citizen);
    },

    updateCitizenList(){
      this.$refs['citizenList'].updateData();
    }
  },
  watch: {
    citizen: function(newVal){
      this.citizenInfo=null;
      this.getCitizenInfo();
    }
  }
}
</script>

<style scoped>
.padding-right{
  padding-right:5px;

}

.padding-left{
  padding-left:5px;
}

.padding-both{
  padding-left:5px;
  padding-right:5px;
}
</style>
<template>
  <div>
  </div>
</template>

<script>
export default {
  name: 'Home',
  created() {
    if (localStorage.getItem('userinfo') !== null) {
      window.location.replace('/profile')
    }
    else {
      window.location.replace('/login')
    }
  }
}
</script>

<style lang="scss">
#Home {
  img {
    max-width: 400px;
  }
}
</style>

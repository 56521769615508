<template>
  <ul class="context-menu show" v-show="show" :style="style">
    <slot></slot>
  </ul>
</template>

<script>
export default {
  name: "ContextMenu",
  data(){
    return {
      left: 0,
      top: 0,
      show: false,
    }
  },
  computed: {
    style(){
      return {
        top: this.top+'px',
        left: this.left+'px'
      }
    }
  },
  created() {
    document.addEventListener('click', () => {
      this.close();
    });
  },
  methods: {
    close(){
      this.show=false;
      this.left=0;
      this.top=0;
    },
    open(evt) {
      setTimeout(()=>{
        this.left = evt.pageX || evt.clientX;
        this.top = evt.pageY || evt.clientY;
        this.show = true;
        this.$nextTick(() => this.$el.focus());
      },50);
    },
    toggle(evt){
      if(this.show){
        this.close();
      } else {
        this.open(evt);
      }
    }
  }
}
</script>

<style scoped>
.context-menu{
  position: fixed;
  z-index:999;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}
</style>
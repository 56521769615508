<template>
  <div>
    <h5 class="text-white">Civilians</h5>
    <table class="table table-striped table-dark table-hover table-bg-darker">
      <thead class="thead-light">
      <tr>
        <th scope="col">Name</th>
        <th scope="col">DOB</th>
        <th scope="col">Gender</th>
        <th scope="col">Ethnicity</th>
        <th scope="col">Occupation</th>
        <th scope="col">&nbsp</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="civ in civilians">
        <td>{{ civ.name }}</td>
        <td>{{ civ.dob }}</td>
        <td>{{ civ.gender.name }}</td>
        <td>{{ civ.ethnicity.name }}</td>
        <td>{{ civ.occupation }}</td>
        <td>
          <router-link :to="{ name:'citizen', params: { citizen:civ.id } }"  class="btn btn-secondary">
            Select
          </router-link>

          <b-btn variant="primary" @click="$emit('edit',civ)">Edit</b-btn>
          <b-btn variant="danger" @click="deleteCivilian(civ)">Delete</b-btn>
        </td>
      </tr>
      </tbody>
    </table>
  </div>

</template>

<script>
import CitizenRepository from "@/api/CitizenRepository";

export default {
  name: "CivilianList",
  data(){
    return {
      civilians:null
    }
  },
  created(){
    this.updateData();
  },

  methods: {
    deleteCivilian(civ){
      CitizenRepository.deleteCitizen(civ.id).then(response => {
        this.updateData();
      });
    },

    updateData(){
      CitizenRepository.getCitizens().then(response => {
        this.civilians=response.data.data;
      });
    }
  }
}
</script>

<style scoped>

.table-bg-darker {
  background-color: #212529;
}

</style>
<template>
  <b-modal no-close-on-backdrop id="modal-warrant-show" size="lg" title="Warrant Information"  v-if="warrant !== null">

    <h6>Civilian Information</h6>
    <hr>

    <b-form-row>
      <b-col cols="4">
        <div class="fw-bold font-weight-bolder dd-title">Full Name</div>
        {{ warrant.civilian.name }}
      </b-col>

      <b-col cols="4">
        <div class="fw-bold font-weight-bolder dd-title">DOB</div>
        {{ warrant.civilian.dob }}
      </b-col>

      <b-col cols="4">
        <div class="fw-bold font-weight-bolder dd-title">Gender</div>
        {{ warrant.civilian.gender.name }}
      </b-col>
    </b-form-row>

    <b-form-row>
      <b-col cols="4">
        <div class="fw-bold font-weight-bolder dd-title">Address</div>
        {{ warrant.civilian.address }}
      </b-col>

      <b-col cols="4">
        <div class="fw-bold font-weight-bolder dd-title">Postal</div>
        {{ warrant.civilian.postal }}
      </b-col>

      <b-col cols="4">
        <div class="fw-bold font-weight-bolder dd-title">Hair Color</div>
        {{ warrant.civilian.hair_color }}
      </b-col>
    </b-form-row>
    <hr>

    <h6>Charges</h6>
    <hr>

    <template v-for="(charge,index) in warrant.charges">
      <b-form-row>
        <b-col cols="2" class="text-center">#{{ (index+1) }}</b-col>
        <b-col cols="10">
          <b-form-row>
            <b-col cols="6">
              <div class="fw-bold font-weight-bolder dd-title">Charge</div>
              {{ charge.title }}
            </b-col>

            <b-col cols="3">
              <div class="fw-bold font-weight-bolder dd-title">Type</div>
              {{ charge.pivot.type }}
            </b-col>

            <b-col cols="3">
              <div class="fw-bold font-weight-bolder dd-title">Counts</div>
              {{ charge.pivot.counts }}
            </b-col>
          </b-form-row>
          <b-form-row>
            <b-col cols="6">
              <div class="fw-bold font-weight-bolder dd-title">Code</div>
              {{ charge.code }}
            </b-col>
            <b-col cols="3">
              <div class="fw-bold font-weight-bolder dd-title">Fine Value</div>
              {{ charge.pivot.fine_value }}
            </b-col>
            <b-col cols="3">
              <div class="fw-bold font-weight-bolder dd-title">Jail Time</div>
              {{ charge.pivot.jail_time }}
            </b-col>
          </b-form-row>
        </b-col>
      </b-form-row>
      <hr>
    </template>


    <h6>Warrant Information</h6>
    <hr>

    <b-form-row>
      <b-col cols="12">
        <div class="fw-bold font-weight-bolder dd-title">Warrant Type</div>
        {{ warrant.warrant_type }}
      </b-col>
    </b-form-row>
    <b-form-row>
      <b-col cols="12">
        <div class="fw-bold font-weight-bolder dd-title">Narrative</div>
        {{ warrant.narrative }}
      </b-col>
    </b-form-row>
    <hr>

    <h6>Filling Officer</h6>
    <hr>

    <b-form-row>
      <b-col cols="4">
        <div class="fw-bold font-weight-bolder dd-title">Identifier</div>
        {{ warrant.officer.identifier }}
      </b-col>

      <b-col cols="4">
        <div class="fw-bold font-weight-bolder dd-title">Officer</div>
        {{ warrant.officer.rank }} {{ warrant.officer.name }}
      </b-col>

      <b-col cols="4">
        <div class="fw-bold font-weight-bolder dd-title">Agency</div>
        {{ warrant.officer.agency.short_name }}
      </b-col>

      <b-col cols="4">
        <div class="fw-bold font-weight-bolder dd-title">Date/Time</div>
        {{ warrant.date_time }}
      </b-col>
    </b-form-row>

    <template #modal-footer="">
      <b-button variant="danger" @click="deleteWarrant()" v-if="doesUserHavePermission('warrant_delete')">
        Delete
      </b-button>
    </template>

  </b-modal>
</template>

<script>
import LeoRepository from "@/api/LeoRepository";

export default {
  name: "WarrantShow",
  props: ['warrant'],
  methods:{
    deleteWarrant(){
      LeoRepository.deleteWarrant(this.warrant.id).then(response => {
        this.bolo=null;
        this.$root.$emit('bv::hide::modal', 'modal-warrant-show')
      }).catch(error => {

      });
    }
  }
}
</script>

<style scoped>

</style>
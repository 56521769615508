<template>
  <b-alert v-model="showErrors" variant="danger">
    <ul v-for="error in errors">
      <li v-for="err in error">{{ err }}</li>
    </ul>
  </b-alert>
</template>

<script>
export default {
  name: "ErrorDisplay",
  props:['errors'],
  computed:{
    showErrors(){
      return this.errors !== null;
    }
  }
}
</script>

<style scoped>

</style>
import Repository from "./Repository";

class LeoRepository {

    getUnits(dispatch=null){
        let qs="";

        if(dispatch!=null) qs+="?dispatch=1";

        return Repository.get('units'+qs);
    };

    getUnit(unitId){
        return Repository.get('units/'+unitId);
    };

    updateUnitStatus(unitId, status){
        return Repository.put('units/'+unitId+'/status', {'current_status_id':status.id});
    };

    updateUnit(unitId, data){
        return Repository.put('units/'+unitId, data);
    };

    createNewUnit(data){
        return Repository.post('units', data);
    }

    getBolos(boloId,all){

        let qs="";

        if(boloId!==null) qs += "/"+boloId;
        if(all===true) qs += "?all=true";

        return Repository.get('bolos'+qs)
    };

    createBolo(data){
        return Repository.post('bolos', data);
    };

    updateBolo(boloId, data){
        return Repository.put('bolos/'+boloId, { description:data.description, wanted_for:data.wanted_for, plate:data.plate, last_seen:data.last_seen });
    };

    deleteBolo(boloId){
        return Repository.delete('bolos/'+boloId);
    };

    getArrestReport(arrestId){
        return Repository.get('arrest-reports/'+arrestId);
    };

    createArrestReport(data){
        return Repository.post('arrest-reports', data);
    }

    getWarrant(warrantId){
        return Repository.get('warrants/'+warrantId);
    };

    createWarrant(data){
        return Repository.post('warrants', data);
    };

    deleteWarrant(warrantId){
        return Repository.delete('/warrants/'+warrantId);
    };

    createCitation(data){
        return Repository.post('leo-citations',data);
    };

    getCitation(citationId){
        return Repository.get('leo-citations/'+citationId);
    };

    deleteCitation(citationId){
        return Repository.delete('leo-citations/'+citationId);
    };

    updateOfficersNotes(civilian,notes){
        return Repository.put('civilians/'+civilian+'/officersNotes',{"officers_notes":notes});
    }

}

export default new LeoRepository();
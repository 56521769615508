<template>
  <b-modal no-close-on-backdrop size="xl" id="information-panel" hide-footer title="Information Panel">
    <div class="row">
      <div class="col-md-6">
        <h5>Penal Codes</h5>
        <b-form-input v-model="search" placeholder="Search..." class="mb-2"></b-form-input>
        <b-list-group class="list-limit">
          <b-list-group-item v-for="p in penalCodes">{{ p.code }} - {{ p.title }}</b-list-group-item>
        </b-list-group>
      </div>
      <div class="col-md-6">
        <h5>10 Codes</h5>
        <b-list-group class="list-limit mt-5">
          <b-list-group-item v-for="c in ten_codes">{{ c.code }}</b-list-group-item>
        </b-list-group>
      </div>
    </div>
  </b-modal>
</template>

<script>
import DataRepository from "@/api/DataRepository";

export default {
  name: "InformationPanel",
  data(){
    return {
      penal_codes:null,
      ten_codes:null,
      search:null
    }
  },
  computed: {
    penalCodes: function(){
      if(this.search === null) return this.penal_codes;
      return this.penal_codes.filter(pc => {
        return pc.code.toLowerCase().includes(this.search.toLowerCase())
            || pc.title.toLowerCase().includes(this.search.toLowerCase())
      })
    }
  },
  mounted() {
    DataRepository.getTenCodes().then(response => {
      this.ten_codes=response.data.data;
    });

    DataRepository.getPenalCodes().then(response => {
      this.penal_codes=response.data.data;
    });
  }
}
</script>

<style scoped>
.list-limit {
  max-height: 70vh;
  overflow: auto;
}
</style>
<template>

  <b-modal no-close-on-backdrop id="call-form-modal" size="lg" title="Manage Call" ref="call-form-modal">

    <b-form-row>
      <b-col cols="3">
        <b-form-group label="Call Origin *" label-for="call_origin">
          <v-select name="call_origin"  v-if="selects.origins!==null" :options="selects.origins"  :reduce="origin => origin.code" v-model="form.call_origin"></v-select>
        </b-form-group>
      </b-col>
      <b-col cols="3">
        <b-form-group label="Call Status *" label-for="call_status">
          <v-select name="call_status" v-if="selects.statuses!==null" :options="selects.statuses"  :reduce="origin => origin.code" v-model="form.call_status"></v-select>
        </b-form-group>
      </b-col>
      <b-col cols="6">
        <b-form-group label="Priority *" label-for="priority">
          <v-select name="priority" v-if="selects.priorities!==null" :options="selects.priorities"  :reduce="origin => origin.code" v-model="form.priority"></v-select>
        </b-form-group>
      </b-col>
    </b-form-row>

    <b-form-row>
      <b-col cols="8">
        <b-form-group label="Address" label-for="address">
          <b-form-input type="text" v-model="form.address"></b-form-input>
        </b-form-group>
      </b-col>
      <b-col cols="4">
        <b-form-group label="Postal" label-for="postal">
          <b-form-input type="text" v-model="form.postal"></b-form-input>
        </b-form-group>
      </b-col>
    </b-form-row>

    <b-form-row>
      <b-col cols="5">
        <b-form-group label="10-Code *" label-for="code">
          <v-select name="code" v-if="selects.codes!==null" :options="selects.codes" label="code" :reduce="code => code.id" v-model="form.code_id" @option:selected="selectCode($event)"></v-select>
        </b-form-group>
      </b-col>
      <b-col cols="7">
        <b-form-group label="Title" label-for="title">
          <b-form-input type="text" v-model="form.title"></b-form-input>
        </b-form-group>
      </b-col>
    </b-form-row>

    <b-form-row>
      <b-col cols="12">
        <b-textarea v-model="form.description" placeholder="Description" rows="3" ></b-textarea>
      </b-col>
    </b-form-row>
    <br>

    <b-form-row v-if="call_id!==null">
      <b-col cols="12">
        <b-textarea v-model="form.notes" placeholder="Notes" rows="6" v-html="true" ></b-textarea>
      </b-col>
    </b-form-row>

    <b-form-row v-if="call_id!==null">
      <b-col cols="9">
        <b-form-input type="text" v-model="note" @keyup.enter.native="addNote"></b-form-input>
      </b-col>
      <b-col cols="3">
        <b-button variant="success" class="form-control" @click="addNote">Add note</b-button>
      </b-col>
    </b-form-row>

    <hr>
    <b-form-row v-if="call_id!==null">
      <h5>Units <span style="font-size: 13px;">(click to detach)</span></h5>
      <b-col>
        <b-badge class="mr-2 pl-3 pr-3 pt-2 pb-2 unit-single" variant="dark" v-for="unit in call.units" :key="unit.id" @click="detachUnit(unit)">{{ unit.identifier }} - {{ unit.rank }}</b-badge>
      </b-col>
    </b-form-row>

    <br>
    <ErrorDisplay :errors="errors"></ErrorDisplay>

    <template #modal-footer>

      <b-button size="sm" variant="success" @click="createCall()" v-if="call_id===null">
        Create
      </b-button>
      <b-button size="sm" variant="success" @click="saveCall()" v-if="call_id!==null">
        Save
      </b-button>
      <b-button size="sm" variant="secondary" @click="cancel()">
        Close
      </b-button>

    </template>

  </b-modal>

</template>

<script>
import DataRepository from "@/api/DataRepository";
import ErrorDisplay from "@/components/Partials/ErrorDisplay";
import DispatchRepository from "@/api/DispatchRepository";
import CitizenRepository from "@/api/CitizenRepository";

export default {
  name: "ManageCall",
  components: {ErrorDisplay},
  data(){
    return {
      form:{
        call_origin:'radio',
        call_status:'active',
        priority: 2,
        address: null,
        postal: null,
        title: null,
        primary_unit: null,
        description: null,
        notes:null,
        code_id:null,
        x:null,
        y:null,
        z:null
      },
      note:null,
      call_id:null,
      call:null,
      civilian_call:null,
      errors:null,
      selects:{
        origins:[
          {code:'radio', label:'Radio'},
          {code:'caller', label:'Caller'}
        ],
        statuses:[
          {code:'pending', label:'Pending'},
          {code:'active', label:'Active'},
          {code:'closed', label:'Closed'},
        ],
        priorities:[
          {code:1, label:'Code 1'},
          {code:2, label:'Code 2'},
          {code:3, label:'Code 3'},
        ],
        codes:[]
      }
    }
  },
  mounted() {
  },
  created() {
    DataRepository.getTenCodes().then(response => {
      this.selects.codes=response.data.data;
    });
  },
  methods: {
    selectCode(evt){
      if(this.form.title === null || this.form.title === ''){
        let sp=evt.code.split(":",2);
        this.form.title=sp[1].trim();
      }
    },

    cancel(){
      this.$refs['call-form-modal'].hide();
    },

    createCall(){
      this.errors=null;
      DispatchRepository.createDispatchCall(this.form).then(response => {
        console.log(response);
        if(this.civilian_call!==null){
          let data=this.civilian_call;
          data.status='closed';
          CitizenRepository.updateCitizenCall(this.civilian_call.id,data);
        }
        this.call_id=response.data.data.id;
      }).catch(error => {
        this.errors=error.response.data.errors;
      });
    },

    saveCall(){
      this.errors=null;
      DispatchRepository.updateDispatchCall(this.form, this.call_id).then(response => {
        let call=response.data.data;
        if(call.call_status==='closed'){
          this.hide();
        }
      }).catch(error => {
        this.errors=error.response.data.errors;
      });
    },

    openCallEditor(call){
      this.resetForm();
      this.form.call_origin='caller';
      this.form.address=call.caller_location;
      this.call_id=null;

      console.log("DataCall:");
      console.log(call);

      this.form.x=call.x;
      this.form.y=call.y;
      this.form.z=call.z;

      let desc="Caller - ";
      console.log(call);
      if(call.bystander_caller === 1){
        desc+=" Bystander/Anonymous -";
      } else {
        desc+=" " + call.caller_name + " - ";
      }

      desc+=call.description;
      this.form.description=desc;
      this.civilian_call=call;

      this.$refs['call-form-modal'].show();
    },

    openCallEdit(call){
      this.resetForm();
      this.form.call_origin=call.call_origin;
      this.form.call_status=call.call_status;
      this.form.priority=call.priority;
      this.form.address=call.address;
      this.form.postal=call.postal;
      this.form.title=call.title;
      this.form.description=call.description;
      this.form.notes=call.notes;
      this.form.code_id=call.code.id;
      this.form.x=call.x;
      this.form.y=call.y;
      this.form.z=call.z;

      this.call_id=call.id;
      this.call=call;

      this.$refs['call-form-modal'].show();
    },

    show(reset=false){
      if(reset){
        this.form={
          call_origin:'radio',
          call_status:'active',
          priority: 2,
          address: null,
          postal: null,
          title: null,
          primary_unit: null,
          description: null,
          notes:null,
          code_id:null
        };
      }
      this.$refs['call-form-modal'].show();

    },
    hide(){
      this.$refs['call-form-modal'].hide();
    },

    addNote(){

      let fnote='';
      if(this.form.notes !== null){
        fnote=this.form.notes;
      }

      let note=this.moment().format('Y-MM-DD HH:mm:ss') + " | " + this.note + "\n";
      this.form.notes=note+fnote;
      this.saveCall();
    },

    detachUnit(unit){
      DispatchRepository.changeUnitsCall('detach',this.call_id,[unit.id]);
    },

    resetForm(){
      this.form={
        call_origin:'radio',
        call_status:'active',
        priority: 2,
        address: null,
        postal: null,
        title: null,
        primary_unit: null,
        description: null,
        notes:null,
        code_id:null,
        x:null,
        y:null,
        z:null
      };
    }
  },
  sockets:{
    updateDispatchCall: function(data){

      if(this.call_id!==null && typeof data.id !== "undefined" && data.id===this.call_id){
        this.form=data;
        this.call=data;
      }

    }
  }
}
</script>

<style scoped>
  .unit-single {
    cursor: pointer;
  }
</style>
<template>
  <b-modal no-close-on-backdrop id="arrest-report-lookup-modal" size="lg" title="Arrest Report">
    <template v-if="arrest_report!==null">
      <h6>Civilian Information</h6>
      <hr>

      <b-form-row>
        <b-col cols="4">
          <div class="fw-bold font-weight-bolder dd-title">Full Name</div>
          {{ arrest_report.civilian.name }}
        </b-col>

        <b-col cols="4">
          <div class="fw-bold font-weight-bolder dd-title">DOB</div>
          {{ arrest_report.civilian.dob }}
        </b-col>

        <b-col cols="4">
          <div class="fw-bold font-weight-bolder dd-title">Gender</div>
          {{ arrest_report.civilian.gender.name }}
        </b-col>
      </b-form-row>

      <b-form-row>
        <b-col cols="4">
          <div class="fw-bold font-weight-bolder dd-title">Address</div>
          {{ arrest_report.civilian.address }}
        </b-col>

        <b-col cols="4">
          <div class="fw-bold font-weight-bolder dd-title">Postal</div>
          {{ arrest_report.civilian.postal }}
        </b-col>

        <b-col cols="4">
          <div class="fw-bold font-weight-bolder dd-title">Hair Color</div>
          {{ arrest_report.civilian.hair_color }}
        </b-col>
      </b-form-row>
      <hr>

      <h6>Charges</h6>
      <hr>

      <template v-for="(charge,index) in arrest_report.charges">
        <b-form-row>
          <b-col cols="2" class="text-center">#{{ (index+1) }}</b-col>
          <b-col cols="10">
            <b-form-row>
              <b-col cols="6">
                <div class="fw-bold font-weight-bolder dd-title">Charge</div>
                {{ charge.title }}
              </b-col>

              <b-col cols="3">
                <div class="fw-bold font-weight-bolder dd-title">Type</div>
                {{ charge.pivot.type }}
              </b-col>

              <b-col cols="3">
                <div class="fw-bold font-weight-bolder dd-title">Counts</div>
                {{ charge.pivot.counts }}
              </b-col>
            </b-form-row>
            <b-form-row>
              <b-col cols="6">
                <div class="fw-bold font-weight-bolder dd-title">Code</div>
                {{ charge.code }}
              </b-col>
              <b-col cols="3">
                <div class="fw-bold font-weight-bolder dd-title">Fine Value</div>
                {{ charge.pivot.fine_value }}
              </b-col>
              <b-col cols="3">
                <div class="fw-bold font-weight-bolder dd-title">Jail Time</div>
                {{ charge.pivot.jail_time }}
              </b-col>
            </b-form-row>
          </b-col>
        </b-form-row>
        <hr>
      </template>


      <h6>Arrest Information</h6>
      <hr>

      <b-form-row>
        <b-col cols="12">
          <div class="fw-bold font-weight-bolder dd-title">Arreste Inventory</div>
          <template v-if="arrest_report.contents!=null">{{ arrest_report.contents }}</template>
          <template v-if="arrest_report.contents===null">N/A</template>
        </b-col>
      </b-form-row>
      <b-form-row>
        <b-col cols="12">
          <div class="fw-bold font-weight-bolder dd-title">Narrative</div>
          {{ arrest_report.narrative }}
        </b-col>
      </b-form-row>
      <hr>

      <h6>Arresting Officer</h6>
      <hr>

      <b-form-row>
        <b-col cols="4">
          <div class="fw-bold font-weight-bolder dd-title">Identifier</div>
          {{ arrest_report.arresting_officer.identifier }}
        </b-col>

        <b-col cols="4">
          <div class="fw-bold font-weight-bolder dd-title">Officer</div>
          {{ arrest_report.arresting_officer.rank }} {{ arrest_report.arresting_officer.name }}
        </b-col>

        <b-col cols="4">
          <div class="fw-bold font-weight-bolder dd-title">Agency</div>
          {{ arrest_report.arresting_officer.agency.short_name }}
        </b-col>

        <b-col cols="4">
          <div class="fw-bold font-weight-bolder dd-title">Date/Time</div>
          {{ arrest_report.date_time }}
        </b-col>
      </b-form-row>


    </template>

  </b-modal>
</template>

<script>
import LeoRepository from "@/api/LeoRepository";

export default {
  name: "ArrestReportShow",
  props: ['arrest_report_id'],
  data(){
    return {
      arrest_report: null
    }
  },
  mounted(){
    this.setArrestReportInfo();
  },
  methods: {
    setArrestReportInfo(){

      LeoRepository.getArrestReport(this.arrest_report_id).then(response => {
        this.arrest_report=response.data.data;
      });

    }
  },
  watch:{
    arrest_report_id: function (newVal, oldVal){
      this.arrest_report=null;
      this.setArrestReportInfo();
    }
  }
}
</script>

<style scoped>

</style>
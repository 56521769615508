/*
* Auth API
*/

import Repository from "./Repository";

class AuthService {

    login(user){
        return Repository.post('login', {
            email: user.email,
            password: user.password
        })
            .then(response => {
                if(response.data.access_token){
                    localStorage.setItem('userinfo', JSON.stringify(response.data));
                }

                return response.data;
            });
    };

    getPermissions(){
        return Repository.get('me');
    }

}

export default new AuthService();

<template>
  <b-card title="Civilian Information" :sub-title="civilian.name">
    <b-card-body>
      <b-form-row>
        <b-col cols="8">

          <b-row>
            <b-col cols="8">
              <div class="fw-bold font-weight-bolder dd-title">Full Name</div>
              {{ civilian.name }}
              <div class="fw-bold font-weight-bolder dd-title">Date of Birth</div>
              {{ civilian.dob }} ({{ civilian.age }})
              <div class="fw-bold font-weight-bolder dd-title">Hair Color</div>
              {{ civilian.hair_color }}
              <div class="fw-bold font-weight-bolder dd-title">Address</div>
              {{ civilian.address }}
              <div class="fw-bold font-weight-bolder dd-title">Height</div>
              {{ civilian.height }}
            </b-col>
            <b-col>
              <div class="fw-bold font-weight-bolder dd-title">Gender</div>
              {{ civilian.gender.name }}
              <div class="fw-bold font-weight-bolder dd-title">Ethnicity</div>
              {{ civilian.ethnicity.name }}
              <div class="fw-bold font-weight-bolder dd-title">Eye Color</div>
              {{ civilian.eye_color }}
              <div class="fw-bold font-weight-bolder dd-title">Postal</div>
              {{ civilian.postal }}
              <div class="fw-bold font-weight-bolder dd-title">Weight</div>
              {{ civilian.weight }}
            </b-col>
          </b-row>
        </b-col>
        <b-col>
          <img :src="picture.url" class="profile-picture" v-if="picture!==null">
        </b-col>
      </b-form-row>
      <b-row>
        <b-col cols="8">
          <div class="fw-bold font-weight-bolder dd-title">Occupation</div>
          {{ civilian.occupation }}
        </b-col>
        <b-col cols="4">
          <div class="fw-bold font-weight-bolder dd-title">Phone Number</div>
          {{ civilian.phone_number }}
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <div class="fw-bold font-weight-bolder dd-title">Medical Records</div>
          <p class="medical-records">{{ civilian.medical_record }}</p>
        </b-col>
      </b-row>
    </b-card-body>


  </b-card>

</template>

<script>
import CitizenRepository from "@/api/CitizenRepository";
import ArrestReport from "@/components/Partials/LEO/Reports/ArrestReport";
import Warrant from "@/components/Partials/LEO/Reports/Warrant";
import CreateBOLO from "@/components/Partials/LEO/Reports/CreateBOLO";
import Citation from "@/components/Partials/LEO/Reports/Citation";
import LeoRepository from "@/api/LeoRepository";

export default {
  name: "CivilianShow",
  components: {Citation, CreateBOLO, Warrant, ArrestReport},
  props: ['civilian'],
  data(){
    return {
      edit:{
        officers_notes:this.civilian.officers_notes
      },
      picture:null
    }
  },
  mounted() {
    CitizenRepository.getPicture(this.$props.civilian.id).then(response => {
      this.picture=response.data.data;
    });
  },
  methods:{
    saveOfficersNotes(){
      if(this.edit.officers_notes!==this.civilian.officers_notes) LeoRepository.updateOfficersNotes(this.civilian.id,this.edit.officers_notes);
      this.$refs['civilian-lookup-modal'].hide();
    }
  }
}
</script>

<style scoped>
  .profile-picture {
    max-height: 300px;
    max-width: 100%;
    float: right;
    vertical-align: top;
  }

  .dd-title:not(:first-child){
    margin-top: 15px;
  }

  .medical-records {
    white-space: break-spaces;
  }
</style>
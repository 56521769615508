<template>
  <div class="row bg-danger text-white font-weight-bold text-center p-1" v-if="isActive">
    <div class="col">
      Signal 100 in effect - {{ text }}
    </div>
  </div>
</template>

<script>
export default {
  name: "Signal100",
  data(){
    return {
      isActive: false,
      text: null
    }
  },
  sockets: {
    signal100update: function(data){
      console.log("Signal 100 update");
      this.isActive=data.isActive;
      this.text=data.text;
      if(this.isActive){
        const audio = new Audio('audio/signal100.mp3');
        audio.play();
      }
    }
  }
}
</script>

<style scoped>

</style>
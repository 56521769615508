<template>

  <b-modal no-close-on-backdrop id="call-modal" size="lg" title="Call Information"  v-if="call !== null">
    <ul class="list-group" v-if="">
      <li class="list-group-item d-flex justify-content-between align-items-start">
        <div class="ms-2 me-auto">
          <div class="fw-bold font-weight-bolder">Caller Name</div>
          {{ call.caller_name }} <span v-if="call.bystander_caller === 1">(Bystander/Anonymous caller)</span>
        </div>
      </li>

      <li class="list-group-item d-flex justify-content-between align-items-start">
        <div class="ms-2 me-auto">
          <div class="fw-bold font-weight-bolder">Location</div>
          {{ call.caller_location }}
        </div>
      </li>

      <li class="list-group-item d-flex justify-content-between align-items-start">
        <div class="ms-2 me-auto">
          <div class="fw-bold font-weight-bolder">Description</div>
          {{ call.description }}
        </div>
      </li>

      <li class="list-group-item d-flex justify-content-between align-items-start">
        <div class="ms-2 me-auto">
          <div class="fw-bold font-weight-bolder">Date/Time</div>
          {{ call.created_at }}
        </div>
      </li>
    </ul>

  </b-modal>

</template>

<script>
export default {
  name: "ShowCall",
  props: ['callSelect'],
  data(){
    return {
      call:null
    }
  },
  mounted(){
    this.call=this.callSelect;
  },
  watch: {
    callSelect: function(newCall){
      console.log("testaaaa");
      this.call=this.callSelect;
    },
  }
}
</script>

<style scoped>

</style>
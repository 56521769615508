<template>
  <div>
    <b-modal no-close-on-backdrop id="citation-form-modal" size="lg" title="Create Citation" @ok="submitCitation">

      <b-form-group
          label="Civilian"
          label-for="civilian"
          v-if="civilian !==null && typeof civilian !== 'undefined'"
      >
        <b-form-input
            id="civilian"
            v-model="civilian.name"
            type="text"
            placeholder="Civilian Name"
            disabled
        ></b-form-input>
      </b-form-group>

      <b-form-group
          label="Date/Time"
          label-for="date_time"
      >
        <b-form-row>
          <b-col cols="8">
            <b-form-input
                id="date_time"
                v-model="form.date"
                type="date"
                required
            ></b-form-input>
          </b-col>
          <b-col cols="4">
            <b-form-input
                id="date_time"
                v-model="form.time"
                type="time"
                required
            ></b-form-input>
          </b-col>
        </b-form-row>

      </b-form-group>

      <b-form-group
          label="Vehicle"
          label-for="vehicle"
      >
        <v-select name="vehicle" v-if="vehicles!==null" :options="vehicles" v-model="form.vehicle" @input="vehicleSelected($event)"></v-select>
      </b-form-group>

      <b-form-group
          label="Vehicle Plate"
          label-for="plate"
      >
        <b-form-input
            id="plate"
            v-model="form.vehicle_plate"
            type="text"
            required

        ></b-form-input>
      </b-form-group>

      <hr>
      <AddCharges @change="chargesChanged"></AddCharges>
      <hr>

      <b-form-group
          label="Narrative"
          label-for="narrative"
      >
        <b-form-textarea
            id="narrative"
            v-model="form.narrative"
            type="text"
            required
            rows="4"
        ></b-form-textarea>
      </b-form-group>

      <ErrorDisplay :errors="errors"></ErrorDisplay>

    </b-modal>
  </div>
</template>

<script>
import CitizenRepository from "@/api/CitizenRepository";
import LeoRepository from "@/api/LeoRepository";
import AddCharges from "@/components/Partials/LEO/Reports/AddCharges";
import ErrorDisplay from "@/components/Partials/ErrorDisplay";

export default {
  name: "Citation",
  components: {ErrorDisplay, AddCharges},
  props: ['civilian'],
  data: function () {
    return {
      form: {
        date: null,
        time: null,
        vehicle: null,
        vehicle_plate: null,
        charges: null,
        narrative: null,
      },
      errors: null,
      vehicles: null
    }
  },
  computed: {
    unit() {
      return this.$store.state.auth.leoUnit;
    }
  },
  mounted() {
    this.form.date=this.moment().format('Y-MM-D');
    this.form.time=this.moment().format('HH:mm:ss');

    if(this.$props.civilian){
      CitizenRepository.getVehicles(this.$props.civilian.id).then(response => {
        let data= response.data.data;
        this.vehicles=data.map(obj=> ({ ...obj, label: obj.color + ' ' + obj.make + ' ' + obj.model + ' - ' + obj.plate }));
      });
    }
  },
  methods: {
    vehicleSelected(event){
      this.form.vehicle_plate=event.plate;
    },
    submitCitation(evt){
      evt.preventDefault();
      let data=this.form;
      data.officer_id=this.unit.id;
      data.date_time=this.form.date+" "+this.form.time;
      data.civilian_id=this.$props.civilian.id;
      if(this.form.vehicle!==null){
        data.vehicle_id=this.form.vehicle.id;
      }
      LeoRepository.createCitation(data).then(response => {
        this.$root.$emit('bv::hide::modal', 'citation-form-modal');
      }).catch(error => {
        this.errors=error.response.data.errors;
      });
    },
    chargesChanged(event){
      this.form.charges=event;
    }
  }
}
</script>

<style scoped>

</style>
<template>
  <div class="container py-4">
    <div class="card">
      <div class="card-header">
        Account Information
      </div>
      <div class="card-body">
        <div class="container float-left">
            <div class="input-group mb-3 input-group-prepend">
              <div class="input-group-prepend">
                <input class="form-control inputPadding" id="username" type="text" :value="user" disabled />
              </div>
            </div>

          <p class="card-text" v-if="doesUserHavePermission('citizen_access')">Citizen Access: Yes</p>
          <p class="card-text" v-else>Citizen Access: None</p>

          <p class="card-text" v-if="doesUserHavePermission('leo_supervisor_access')">Police Access: Supervisor </p>
          <p class="card-text" v-else-if="doesUserHavePermission('leo_access')">Police Access: Officer </p>
          <p class="card-text" v-else>Police Access: None</p>

          <p class="card-text" v-if="doesUserHavePermission('fire_em_access')">EMS/FD Access: Yes</p>
          <p class="card-text" v-else>EMS/FD Access: None </p>

          <p class="card-text" v-if="doesUserHavePermission('dispatch_access')">Dispatch Access: Yes</p>
          <p class="card-text" v-else>Dispatch Access: None </p>

          <a href="citizen" class="btn btn-primary" v-if="doesUserHavePermission('citizen_access')">
            <router-link class="text-white" to="/citizen">Civilian Dashboard</router-link>
          </a>
          <a href="leo" class="btn btn-primary" v-if="doesUserHavePermission('leo_access')">
            <router-link class="text-white" to="/leo">LEO Dashboard</router-link>
          </a>
          <a href="fd" class="btn btn-primary" v-if="doesUserHavePermission('fire_em_access')">
            <router-link class="text-white" to="/fd">EMS/FD Dashboard</router-link>
          </a>
          <a href="dispatch" class="btn btn-primary" v-if="doesUserHavePermission('dispatch_access')">
            <router-link class="text-white" to="/dispatch">Dispatch Dashboard</router-link>
          </a>
          <span class="float-right cad-version">v. {{$cadVersion}}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
name: "Profile",
  data() {
    return {
      dark: 'false'
    }
  },

  computed: {
    user(){
      return this.$store.state.auth.user.name
    }
  }
}
</script>

<style scoped>
.inputPadding {
  padding-right: 15px;
  padding-left: 15px;
}

.cad-version{
  color: #3e3e3e;
  position: absolute;
  text-align: right;
  bottom:0;
  right:5px;
}
</style>
import Repository from "./Repository";

class FdRepository {

    createMedicalRecord(data){
        return Repository.post('medical-records', data);
    }

}

export default new FdRepository();
import Repository from "./Repository";

class DataRepository {

    getGenders(){
        return Repository.get('genders');
    };

    getEthnicities(){
        return Repository.get('ethnicities');
    };

    getDeparments(){
        return Repository.get('departments');
    };

    getUnitStatus(){
        return Repository.get('unit-statuses');
    };

    getPenalCodes(){
        return Repository.get('penal-codes');
    };

    getTenCodes(){
        return Repository.get('ten-codes');
    };

    getLicenseTypes(){
        return Repository.get('licenses');
    }
}

export default new DataRepository();
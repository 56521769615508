<template>
  <div class="card">
    <div class="card-header text-black font-weight-bold" :style="{ backgroundColor: unitStatusColor }">
      <div class="row">
        <div class="col">Current Call</div>
        <div class="col text-right " v-if="unit!==null && typeof unit !== 'undefined'"> {{ unit.current_status.code }} {{ unit.current_status.name }}  </div>
      </div>
    </div>
    <div class="card-body">
      <ul class="list-group list-group-flush" v-if="call!==null && typeof call !== 'undefined'">
        <li class="list-group-item"><b>Location:</b> {{ call.address }} ({{ call.postal}})</li>
        <li class="list-group-item"><b>Situation:</b> {{ call.title }}</li>
        <li class="list-group-item"><b>Description:</b> {{ call.description }}</li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "Status",
  data(){
    return {
      call: null,
    }
  },
  computed: {
    unitStatusColor(){
      if(this.unit !== null) return this.unit.current_status.color;
      else return '';
    },
    unit() {
      return this.$store.state.auth.leoUnit;
    }
  },
  watch: {
    unit(newUnit){
      this.call=newUnit.call;
    }
  }
}
</script>

<style>

</style>
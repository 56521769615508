<template>
  <div>
    <b-modal no-close-on-backdrop id="arrest-report-form-modal" size="lg" title="Create Arrest Report" @ok="submitArrest">

      <b-form-group
          label="Civilian"
          label-for="civilian"
      >
        <b-form-input
            id="civilian"
            v-model="civilian.name"
            type="text"
            placeholder="Civilian Name"
            disabled
        ></b-form-input>
      </b-form-group>

      <AddCharges @change="chargesChanged"></AddCharges>

      <hr>

      <b-form-group
          label="Date/Time"
          label-for="date_time"
      >
        <b-form-row>
          <b-col cols="8">
            <b-form-input
                id="date_time"
                v-model="form.date"
                type="date"
                required
            ></b-form-input>
          </b-col>
          <b-col cols="4">
            <b-form-input
                id="date_time"
                v-model="form.time"
                type="time"
                required
            ></b-form-input>
          </b-col>
        </b-form-row>

      </b-form-group>

      <b-form-group
          label="Contents"
          label-for="contents"
      >
        <b-form-input
            id="contents"
            v-model="form.contents"
            type="text"
            placeholder="Inventory"

        ></b-form-input>
      </b-form-group>

      <b-form-group
          label="Narrative"
          label-for="narrative"
      >
        <b-form-textarea
            id="narrative"
            v-model="form.narrative"
            type="text"
            required
            rows="4"
        ></b-form-textarea>
      </b-form-group>

      <ErrorDisplay :errors="errors"></ErrorDisplay>

    </b-modal>
  </div>
</template>

<script>
import AddCharges from "@/components/Partials/LEO/Reports/AddCharges";
import LeoRepository from "@/api/LeoRepository";
import ErrorDisplay from "@/components/Partials/ErrorDisplay";
export default {
  name: "ArrestReport",
  components: {ErrorDisplay, AddCharges},
  props: ['civilian'],
  data: function () {
    return {
      form: {
        charges: null,
        date: null,
        time: null,
        contents: null,
        narrative: null,
      },
      errors: null
    }
  },
  methods: {
    chargesChanged(event){
      this.form.charges=event;
    },
    submitArrest(evt){
      evt.preventDefault();
      let data=this.form;
      data.arresting_officer_id=this.unit.id;
      data.civilian_id=this.civilian.id;
      data.date_time=this.form.date+" "+this.form.time;
      LeoRepository.createArrestReport(data).then(response => {
        this.$root.$emit('bv::hide::modal', 'arrest-report-form-modal');
      }).catch(error => {
        this.errors=error.response.data.errors;
      });
    }
  },
  computed: {
    unit() {
      return this.$store.state.auth.leoUnit;
    }
  },
  mounted() {
    this.form.date=this.moment().format('Y-MM-D');
    this.form.time=this.moment().format('HH:mm:ss');
  }
}
</script>

<style scoped>

</style>
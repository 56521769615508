<template>
  <BaseTemplate></BaseTemplate>
</template>

<style>
  .row:not(:first-child) {
    margin-top: 10px;
  }

  .card {
    border: none !important;
  }

  .btn:not(:first-child) {
    margin-left: 10px;
  }

  .modal-backdrop {
    opacity: 0.5 !important;
  }

  .btn:focus,
  .btn:active{
    box-shadow:none !important;
    outline:0px !important;
  }
</style>
<script>
import BaseTemplate from "@/components/BaseTemplate";
export default {
  components: {BaseTemplate}
}
</script>
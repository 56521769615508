<template>
  <div id="map"></div>
</template>

<script>

import "leaflet/dist/leaflet.css"
import L from "leaflet";
//import {LMap, LImageOverlay, LMarker} from "@vue-leaflet/vue-leaflet";
import { CRS } from "leaflet/dist/leaflet-src.esm";

export default {
  name: "Livemap",
  data(){
    return {
      markers: {
        'citizenCalls':{},
        'units':{},
        'dispatchCalls':{}
      },
      players:null,
      markerTypes:{
        999: {
          iconUrl: "images/icons/" + "/debug.png",
          iconSize: [23, 32],
          popupAnchor: [0, 0],
          iconAnchor: [11.5, 0] // Bottom middle
        },
        6: {
          iconUrl: "images/icons/" + "/normal.png",
          iconSize: [22, 32],
          popupAnchor: [0, 0],
          iconAnchor: [11, 0]
        },
        '10-6': {
          iconUrl: "images/icons/" + "/10-6.png",
          iconSize: [16, 16],
          popupAnchor: [0, 16],
          iconAnchor: [0, -16]
        },
        '10-8': {
          iconUrl: "images/icons/" + "/10-8.png",
          iconSize: [16, 16],
          popupAnchor: [0, 16],
          iconAnchor: [0, -16]
        },
        '10-23': {
          iconUrl: "images/icons/" + "/10-23.png",
          iconSize: [16, 16],
          popupAnchor: [0, 16],
          iconAnchor: [0, -16]
        },
        '10-97': {
          iconUrl: "images/icons/" + "/10-97.png",
          iconSize: [16, 16],
          popupAnchor: [0, 16],
          iconAnchor: [0, -16]
        }
      }
    }
  },
  mounted() {

    let access = this.doesUserHavePermission('dispatch_access');

    if(!access){
      this.$router.push('/profile');
    }


    window.CurrentLayer = undefined;

    window.CurrentLayer= L.tileLayer('images/tiles/minimap_{y}_{x}.png',{ minZoom: -2, maxZoom: 4, tileSize: 1024, maxNativeZoom: 0, minNativeZoom: 0, tileDirectory: 'images/tiles' });

    window.Map = L.map('map', {
      crs: L.CRS.Simple,
      layers: [CurrentLayer]
    }).setView([0,0], 0);

    Map.on("load",function() { setTimeout(() => {
      console.log("Invalidating size...");
      Map.invalidateSize();
    }, 1); });

    var mapBounds = this.getMapBounds(CurrentLayer);

    Map.setMaxBounds(mapBounds);
    Map.fitBounds(mapBounds);

  },
  async beforeMount() {
    // HERE is where to load Leaflet components!
    //const { circleMarker } = await import("leaflet/dist/leaflet-src.esm");
  },
  methods: {
    getMapBounds(layer){
      var h = layer.options.tileSize * 3,
          w = layer.options.tileSize * 2;

      var southWest = Map.unproject([0, h], 0);
      var northEast = Map.unproject([w, 0], 0);

      return new L.LatLngBounds(southWest, northEast);
    },
    createMarker(animated, draggable, objectRef, title, markerUid, markerFolder) {

      var name = objectRef.reference;
      if (name === "@DEBUG@@Locator") {
        name = "@Locator";
      }
      objectRef.position = this.stringCoordToFloat(objectRef.position);
      //console._log(objectRef.position);
      var coord = this.convertToMap(objectRef.position.x, objectRef.position.y);
      //console._log(coord);
      var markerType = objectRef.type;

      //console._log(JSON.stringify(locationType));

      var html = "";

      if (objectRef.description !== ""){
        html += '<div class="row info-body-row">' + objectRef.description + "</div>";
      }

      var infoContent = '<div class="info-window"><div class="info-header-box"><div class="info-header"><b>' + name + '</b></div></div><div class="clear"></div><div class=info-body>' + html + "</div></div>";

      var image = L.icon(markerType);

      var where = window.Map;
      if(objectRef.data && objectRef.data.isPlayer && window.config.groupPlayers){
        // Add to the cluster layer
        where = window.PlayerMarkers;
      }
      if(where === undefined){
        console.warn("For some reason window.Map or window.PlayerMarkers is undefined");
        console.warn("Cannot add the blip: " + objectRef);
        where = createClusterLayer();
      }

      var marker = L.marker(coord, {
        title: title,
        id: this.markers.length,
        icon: image,
        object: objectRef,
        player: objectRef.data.player ? objectRef.data.player : undefined,
        draggable: draggable ? true : false
      }).addTo(where).bindPopup(infoContent);

      this.markers[markerFolder][markerUid]=marker;
      return this.markers[markerFolder].length;
    },
    Coordinates(x, y, z) {
      this.x = x;
      this.y = y;
      this.z = z;
    },
    MarkerObject(reference, position, type, description, data) {
      this.reference = reference;
      this.position = position;
      this.type = type;
      this.description = description;
      this.data = data;
    },
    convertToMap(x, y) {
      var h = CurrentLayer.options.tileSize * 3,
          w = CurrentLayer.options.tileSize * 2;

      var latLng1 = Map.unproject([0, 0], 0);
      var latLng2 = Map.unproject([w / 2, (h - CurrentLayer.options.tileSize)], 0);

      var game_1_x = -4000.00 - 200;
      var game_1_y = 8000.00 + 420;
      var game_2_x = 400.00 - 30;
      var game_2_y = -300.0 - 340.00;

      var rLng = latLng1.lng + (x - game_1_x) * (latLng1.lng - latLng2.lng) / (game_1_x - game_2_x);
      var rLat = latLng1.lat + (y - game_1_y) * (latLng1.lat - latLng2.lat) / (game_1_y - game_2_y);
      return {
        lat: rLat,
        lng: rLng
      };
    },
    stringCoordToFloat(coord) {
      return {
        x: parseFloat(coord.x),
        y: parseFloat(coord.y),
        z: parseFloat(coord.z),
      }
    },
    clearMarker(id,markerFolder) {
      if (this.markers[markerFolder][id] !== null) {
        this.markers[markerFolder][id].remove();
        delete this.markers[markerFolder][id];
      }
    },
    clearAllMarkers(markerFolder) {
      for (let k in this.markers[markerFolder]) {
        this.clearMarker(k,markerFolder);
      }
    },


  },
  sockets:{
    updateUnitsPositions: function(data){

      this.clearAllMarkers('units');
      for(let k in data){
        let unit=data[k];
        if(unit.current_status.code === '10-7') break;

        let desc="<span style='font-weight: bolder; color:"+unit.current_status.color+"'>"+unit.current_status.name+"</span><br>"+unit.street+"<br>"+unit.agency.short_name+"<br>"+unit.rank+" "+unit.name;

        let objc=new this.MarkerObject(unit.identifier, new this.Coordinates(unit.x,unit.y,unit.z), this.markerTypes[unit.current_status.code], desc,"","" );
        this.createMarker(true,false,objc,"", "unit"+unit.identifier,"units");


      }
    },
    incomingCitizenCall: function(data){
      let services=data.calltype.services.split(',');
      if(services.includes('dispatch')){
        let desc=data.description.slice(0,100);
        let objc=new this.MarkerObject(data.calltype.name, new this.Coordinates(data.x,data.y,data.z), this.markerTypes[999], desc,"","" );
        this.createMarker(true,false,objc,"", data.id,"citizenCalls");
      }
    },
    updatedCitizenCall: function(data){
      if(this.markers['citizenCalls'].hasOwnProperty(data.id) && data.status === 'closed'){
        this.clearMarker(data.id,'citizenCalls');
      }
    },
    incomingDispatchCall: function(data){
        let desc=data.description.slice(0,100);
        let objc=new this.MarkerObject(data.title, new this.Coordinates(data.x,data.y,data.z), this.markerTypes[6], desc,"","" );
        this.createMarker(true,false,objc,"", data.id,"dispatchCalls");
    },
    updateDispatchCall: function(data){
      console.log(this.markers['dispatchCalls'].hasOwnProperty(data.id) && data.call_status === 'closed');
      if(this.markers['dispatchCalls'].hasOwnProperty(data.id) && data.call_status === 'closed'){
        this.clearMarker(data.id,'dispatchCalls');
      }
    },
  }

}
</script>

<style scoped>
#map {
  height: 100vh;
  width: 100%;
  background-color: #143d6b;
}
</style>
<template>
  <div>
    <ValidateUnit v-if="unit===null"></ValidateUnit>
    <Signal100></Signal100>
    <div class="header-fix mt-3" v-if="doesUserHavePermission('dispatch_access')">
      <div class="container">
        <div class="row">
          <div class="col">
            <button type="button" class="btn btn-info btn-block" v-b-modal.information-panel>Information (Penal Codes, 10 Codes)</button>
            <InformationPanel></InformationPanel>
          </div>
          <div class="col">
            <b-button variant="primary" block v-b-modal.lookup-modal>Lookup</b-button>
            <Lookup></Lookup>
          </div>
          <div class="col" v-if="doesUserHavePermission('bolo_create')">
            <b-button variant="primary" block v-b-modal.createBOLO-form-modal>Create BOLO</b-button>
            <CreateBOLO></CreateBOLO>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <Modal></Modal>
          </div>
          <div class="col">
            <button type="button" class="btn btn-primary btn-block" @click="$refs['callCreate'].show(true)">Create Call</button>
          </div>
          <div class="col">
            <b-button block variant="warning" v-b-modal.notepad>Notepad</b-button>
          </div>
          <Notepad :block="true" type="dispatch"></Notepad>
          <div class="col">
            <b-button class="btn" variant="danger" v-if="isActive!==true" block v-b-modal.signal100-modal>Signal 100</b-button>
            <b-button class="btn" variant="danger" v-else block v-on:click="activateSignal100">Disable Signal 100</b-button>
            <Signal100Modal></Signal100Modal>
          </div>
        </div>
        <div class="row bs-linebreak">
        </div>

      </div>
      <div class="container-fluid">
        <div class="col">
          <div class="row align-items-start">
            <div class="col-6">
              <UnitList :dispatch="1"></UnitList>
              <h5 class="text-white">Citizen Calls</h5>
              <CallList service="dispatch"></CallList>
            </div>



            <div class="col-6">
              <h5 class="text-white">Active Calls</h5>
              <DispatchCallList></DispatchCallList>

                <h5 class="text-white">Active BOLOs</h5>
                <div class="table-wrapper-scroll-y my-custom-scrollbar">
                  <BOLO></BOLO>
                </div>

            </div>


          </div>
        </div>
      </div>
    </div>
    <ManageCall ref="callCreate"></ManageCall>
  </div>
</template>

<script>


import BOLO from "@/components/Partials/LEO/BOLO";
import Notepad from "@/components/Partials/Notepad";
import UnitList from "@/components/Partials/LEO/Units/UnitList";
import Modal from "@/components/Partials/Dispatch/Live Map/LivemapModal";
import EditUnit from "@/components/Partials/Dispatch/EditUnit";
import Lookup from "@/components/Partials/LEO/Lookup/Lookup";
import CallList from "@/components/Partials/LEO/Calls/CallList";
import ManageCall from "@/components/Partials/Dispatch/ManageCall";
import DispatchCallList from "@/components/Partials/Dispatch/DispatchCallList";
import CreateBOLO from "@/components/Partials/LEO/Reports/CreateBOLO";
import Signal100Modal from "@/components/Partials/Dispatch/Signal100Modal";
import Signal100 from "@/components/Partials/LEO/Signal100";
import DispatchRepository from "@/api/DispatchRepository";
import ValidateUnit from "@/components/Partials/ValidateUnit";
import InformationPanel from "@/components/Partials/Dispatch/InformationPanel";

export default {
  name: "Dispatch",
  data(){
    return {
      isActive: false,
      text: null
    }
  },
  components: {
    InformationPanel,
    ValidateUnit,
    Signal100,
    Signal100Modal,
    CreateBOLO, DispatchCallList, ManageCall, CallList, Lookup, EditUnit, Modal, Notepad, BOLO, UnitList},
  sockets: {
    signal100update: function(data){
      this.isActive=data.isActive;
      this.text=data.text;
    }
  },
  computed: {
    unit() {
      return this.$store.state.auth.leoUnit;
    }
  },
  methods: {
    activateSignal100() {
      DispatchRepository.signal100update();
    }
  }
}
</script>

<style scoped>
.my-custom-scrollbar {
  max-width: 100%;
  max-height: 300px;
  overflow: auto;
}
.table-wrapper-scroll-y {
  display: block;
}
.bs-linebreak {
  height:10px;
}

.padding-both{
  padding-left:5px;
  padding-right:5px;
}
</style>
<template>
  <div>
    <b-button class="btn btn-block" variant="primary" v-b-modal.vehicle-form-modal @click="openVehicleFormNew">Register New Vehicle</b-button>

    <b-modal no-close-on-backdrop id="vehicle-form-modal" size="md" title="Vehicle Registration" ref="newVehicleModal">
      <b-form-row>
        <b-col>
          <b-form-group
              label="Plate"
              label-for="plate"
          >
            <b-form-input
                id="plate"
                v-model="form.plate"
                type="text"
                required
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-form-row>
      <b-form-row>
        <b-col>
          <b-form-group
              label="Make"
              label-for="make"
          >
            <b-form-input
                id="make"
                v-model="form.make"
                type="text"
                required
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
              label="Model"
              label-for="model"
          >
            <b-form-input
                id="model"
                v-model="form.model"
                type="text"
                required
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-form-row>
      <b-form-row>
        <b-col>
          <b-form-group
              label="Color"
              label-for="color"
          >
            <b-form-input
                id="color"
                v-model="form.color"
                type="text"
                required
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
              label="Year"
              label-for="year"
          >
            <b-form-input
                id="year"
                v-model="form.year"
                type="text"
                required
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-form-row>

      <ErrorDisplay :errors="errors"></ErrorDisplay>

      <template #modal-footer>
        <div class="w-100 text-right">

          <template v-if="vehicle===null">
            <b-button
                variant="success"
                size="sm"
                @click="createVehicle"
            >
              Create
            </b-button>
          </template>
          <template v-if="vehicle!==null">
            <b-button
                variant="success"
                size="sm"
                @click="updateVehicle"
            >
              Update
            </b-button>
            <b-button
                variant="danger"
                size="sm"
                @click="deleteVehicle"
            >
              Delete
            </b-button>
          </template>

          <b-button
              variant="secondary"
              size="sm"
              @click="$refs['newVehicleModal'].hide"
          >
            Close
          </b-button>
        </div>
      </template>


    </b-modal>
  </div>
</template>

<script>
import CitizenRepository from "@/api/CitizenRepository";
import ErrorDisplay from "@/components/Partials/ErrorDisplay";

export default {
  name: "NewVehicle",
  components: {ErrorDisplay},
  props:['civilian'],
  data: function () {
    return {
      form: {
        owner_id: null,
        plate: null,
        color: null,
        make: null,
        model: null,
        year: null,
      },
      vehicle:null,
      errors: null
    }
  },
  created() {
    this.form.owner_id=this.civilian.id;
  },
  methods: {

    createVehicle: function(){
      CitizenRepository.createCitizenVehicle(this.form).then(response => {
        this.$refs['newVehicleModal'].hide();
        this.$emit('updateCivilianData');
      }).catch(error => {
        this.errors=error.response.data.errors;
      });
    },

    updateVehicle: function(){
      CitizenRepository.updateCitizenVehicle(this.form, this.vehicle.id).then(response => {
        this.$refs['newVehicleModal'].hide();
        this.$emit('updateCivilianData');
      }).catch(error => {
        this.errors=error.response.data.errors;
      });
    },

    deleteVehicle: function(){
      CitizenRepository.deleteCitizenVehicle(this.vehicle.id).then(response => {
        this.$refs['newVehicleModal'].hide();
        this.$emit('updateCivilianData');
      }).catch(error => {
        this.errors=error.response.data.errors;
      });
    },

    openVehicleFormUpdate: function(vehicle){
      this.vehicle=vehicle;
      this.form.plate=vehicle.plate;
      this.form.color=vehicle.color;
      this.form.year=vehicle.year;
      this.form.make=vehicle.make;
      this.form.model=vehicle.model;
      this.$refs['newVehicleModal'].show();
    },

    openVehicleFormNew: function(){
      this.vehicle=null;
      this.form.plate=null;
      this.form.color=null;
      this.form.year=null;
      this.form.make=null;
      this.form.model=null;
    },


  }
}
</script>

<style scoped>

</style>
<template>
  <nav class="navbar navbar-expand-lg navbar-dark bg-secondary">
    <div class="navbar-collapse collapse order-1 order-md-0 dual-collapse2 centeringThing">
      <a class="navbar-brand" href="profile">CAD</a>
      <ul class="navbar-nav mr-auto">
        <li class="nav-item" v-for="item in menuItems" v-if="doesUserHavePermission(item.permission)">
          <router-link class="nav-link" :to="item.route">{{ item.title }}</router-link>
        </li>
      </ul>
    </div>
    <div class="mx-auto order-0">
      <UnitIdentifier :noctx='true' v-if="user"></UnitIdentifier>
    </div>
    <div class="navbar-collapse collapse order-3 dual-collapse2 centeringThing">
      <div class="clock ml-auto text-center">
        {{ clock_date }}<br>
        {{ clock_time }}
      </div>
      <ul class="navbar-nav ml-5">
        <b-nav-item-dropdown right v-if="user">
          <template #button-content>
            {{ user }}
          </template>
          <b-dropdown-item href="profile">
            <router-link class="text-dark" to="/profile">Profile</router-link>
          </b-dropdown-item>
          <b-dropdown-item v-on:click="logout()" href="login">Sign Out</b-dropdown-item>
        </b-nav-item-dropdown>
      </ul>
    </div>
  </nav>

</template>

<script>
import UnitIdentifier from "@/components/Partials/LEO/Units/UnitIdentifier";
export default {
  name: "Header",
  components: {UnitIdentifier},
  data() {
    return {
      menuItems:[
        {
          title: 'Police',
          permission: 'leo_access',
          route:'/leo'
        },
        {
          title: 'Fire/EMS',
          permission: 'fire_em_access',
          route:'/fd'
        },
        {
          title: 'Dispatch',
          permission: 'dispatch_access',
          route:'/dispatch'
        },
        {
          title: 'Civilian',
          permission: 'citizen_access',
          route:'/citizen'
        },
      ],
      clock_date:null,
      clock_time:null
    }
  },
  methods: {
    logout: function() {
      localStorage.removeItem("userinfo");
      localStorage.removeItem("leoUnit");
    },
    refreshClock(){
      this.clock_date=this.moment().format('LL');
      this.clock_time=this.moment().format('HH:mm');
    }
  },
  computed: {
    unit() {
      return this.$store.state.auth.leoUnit;
    },
    user(){
      let a = this.$store.state.auth.user
      if(typeof a === "undefined" || a === null){
        return ""
      }
      else{
        return a.name
      }
    }
  },
  mounted() {
    this.refreshClock();
    setInterval(e=> { this.refreshClock(); }, 10000);
  }
}

</script>

<style scoped>
.centeringThing{
  width: 40%;
}
</style>
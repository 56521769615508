<template>
  <table class="table">
    <thead class="thead-light">
      <tr>
        <th v-for="header in headerItems">{{ header.title }}</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(k,index) in data" @click="openModal(index)" class="tr-data">
        <td v-for="header in headerItems">{{ header.key.split('.').reduce((p,c)=>p&&p[c]||null, k) }}</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: "LookupTable",
  props: ['data','headerItems','type'],
  methods: {
    openModal(value){
      this.$emit('showLookup', value);
    }
  }
}
</script>

<style scoped>
  .tr-data {
    cursor: pointer;
  }
</style>
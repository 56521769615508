<template>

  <div>
    <h5>Charges</h5>

    <b-row>
      <b-col>
        <b-btn variant="primary" v-on:click="addCharge()">Add charge</b-btn>
        <b-btn variant="primary" v-on:click="removeCharge()">Remove charge</b-btn>
      </b-col>
    </b-row>

    <template v-for="k in numCharges">
      <b-form-row>
        <b-col>
          <b>Charge {{  k  }}</b>
        </b-col>
      </b-form-row>

      <b-form-row>
        <b-col cols="6">
          <b-form-group label="Charge" label-for="penalCode">
            <v-select name="penalCode" v-if="penalCodes!==null" :options="penalCodes" label="title" v-model="charges[k-1].penal_code" @input="penalCodeSelected(k-1,$event)"></v-select>
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group label="Type">
            <b-form-select v-model="charges[k-1].type" :options="optionType" @change="triggerUpdateMain()"></b-form-select>
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group label="Counts">
            <b-form-input type="number" v-model="charges[k-1].counts" @change="triggerUpdateMain()"></b-form-input>
          </b-form-group>
        </b-col>
      </b-form-row>

      <b-form-row>
        <b-col>
          <b-form-group label="Jail Time">
            <b-form-input type="text" v-model="charges[k-1].jail_time" @change="triggerUpdateMain()"></b-form-input>
          </b-form-group>
        </b-col>

        <b-col>
          <b-form-group label="Fine Value">
            <b-form-input type="text" v-model="charges[k-1].fine_value" @change="triggerUpdateMain()"></b-form-input>
          </b-form-group>
        </b-col>

      </b-form-row>

    </template>
  </div>




</template>

<script>
import DataRepository from "@/api/DataRepository";

export default {
  name: "AddCharges",
  data(){
    return {
      penalCodes:null,
      numCharges:0,
      optionType:[
          { value:'felony',text:'Felony' },
          { value:'misdemeanor',text:'Misdemeanor' },
          { value:'infraction',text:'Infraction' },
          { value:'warning',text:'Warning' },
      ],
      charges:[]

    }
  },
  created() {
    DataRepository.getPenalCodes().then( response => {
      this.penalCodes=response.data.data;
      this.addCharge();
    });
  },
  methods: {
    addCharge(){
      this.charges[this.numCharges]={
        penal_code_id: null,
        type: null,
        counts: 1,
        jail_time:'',
        fine_value:''
      };
      this.numCharges=this.numCharges+1;
      console.log(this.charges)
    },
    removeCharge(){
      if(this.charges.length > 1){
        this.charges.splice(-1, 1);
        this.numCharges=this.numCharges-1;
      }
    },
    penalCodeSelected(index, event){
      console.log("Penal code selected - " + index);

      let newObject={
        penal_code_id: event.id,
        type: event.charge_type,
        counts: 1,
        jail_time:event.jail_type,
        fine_value:event.fine_value
      };

      this.charges.splice(index,1,newObject);
      this.triggerUpdateMain();
    },
    triggerUpdateMain(){
      this.$emit('change',this.charges);
    },
  }
}
</script>

<style scoped>

</style>
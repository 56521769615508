<template>
  <div>
    <Header v-if="currentRouteName!=='livemap'"></Header>
    <div class="container-fluid bg-dark" v-if="currentRouteName!=='livemap'">
      <main>
          <router-view></router-view>
      </main>
    </div>
    <div  v-if="currentRouteName==='livemap'">
      <router-view></router-view>
    </div>
  </div>

</template>

<script>

import Header from '@/components/Base/Header'
import LeoRepository from "@/api/LeoRepository";

export default {
  name: "BaseTemplate",
  components: { Header },
  mounted() {
    console.log('what are you doing here?')
    let leoUnit = localStorage.getItem('leoUnit');
    if(leoUnit !== null){
      let uid=leoUnit;
      LeoRepository.getUnit(uid).then(response => {
        if(typeof this.$store !== 'undefined') this.$store.commit('auth/leoUnitSet',response.data.data);
      });
    }
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    }
  }
}
</script>

<style scoped>
.container-fluid {
  min-height: 100vh;
  margin-top:-7.5vh;
  padding-top: 7.5vh;
}

body {
  overflow-y: hidden; /* Hide vertical scrollbar */
  overflow-x: hidden; /* Hide horizontal scrollbar */
  background-color: #343a40;
}

</style>
<template>
  <div>
    <ValidateUnit v-if="unit===null"></ValidateUnit>
    <Signal100></Signal100>
    <div class="row mt-4">
      <div class="col-4">
      </div>
      <div class="col">
        <div class="row">
          <div class="col text-center">
            <StatusButtons :unit="unit" :status-buttons="statusButtons"></StatusButtons>
          </div>
        </div>
        <div class="row">
          <div class="col text-center">
            <b-button variant="primary"  v-b-modal.lookup-modal>Lookup</b-button>
            <b-button class="btn" variant="primary" v-b-modal.createBOLO-form-modal v-if="doesUserHavePermission('bolo_create')">Create BOLO</b-button>
            <b-button class="btn" variant="warning" v-b-modal.notepad>Notepad</b-button>
            <button type="button" class="btn btn-info" v-b-modal.information-panel>Information</button>
            <InformationPanel></InformationPanel>
            <Notepad :block="false" type="leo"></Notepad>
            <Lookup></Lookup>
            <CreateBOLO v-if="doesUserHavePermission('bolo_create')"></CreateBOLO>

          </div>
        </div>
      </div>

        <div class="col-4">

        </div>

    </div>


    <div class="row align-items-start mt-4">
     <div class="col-5">
       <h5 class="text-white">911 Calls</h5>
       <CallList service="leo"></CallList>
       <UnitList :dispatch="1" v-if="doesUserHavePermission('dispatch_access')"></UnitList>

     </div>
     <div class="col-3">
       <h5 class="text-white">Status</h5>
       <Status :unit="unit"></Status>
     </div>
     <div class="col-4">
       <h5 class="text-white">Be On The Lookout</h5>
         <BOLO></BOLO>
     </div>
      
    </div>
  </div>
</template>

<script>
import BOLO from "@/components/Partials/LEO/BOLO"
import Status from "@/components/Partials/LEO/Status"
import StatusButtons from "@/components/Partials/LEO/StatusButtons";
import UnitIdentifier from "@/components/Partials/LEO/Units/UnitIdentifier";
import DataRepository from "@/api/DataRepository";
import LeoRepository from "@/api/LeoRepository";
import ArrestReport from "@/components/Partials/LEO/Reports/ArrestReport";
import Citation from "@/components/Partials/LEO/Reports/Citation";
import Warrant from "@/components/Partials/LEO/Reports/Warrant";
import CreateBOLO from "@/components/Partials/LEO/Reports/CreateBOLO";
import CallList from "@/components/Partials/LEO/Calls/CallList";
import Lookup from "@/components/Partials/LEO/Lookup/Lookup";
import Signal100 from "@/components/Partials/LEO/Signal100";
import Notepad from "@/components/Partials/Notepad";
import ValidateUnit from "@/components/Partials/ValidateUnit";
import InformationPanel from "@/components/Partials/Dispatch/InformationPanel";
import UnitList from "@/components/Partials/LEO/Units/UnitList";
 
export default {
  name: "LEO",
  components: {
    UnitList,
    InformationPanel,
    ValidateUnit,
    Notepad,
    Signal100,
    Lookup,
    CallList,
    CreateBOLO,
    Warrant,
    Citation,
    ArrestReport,
    UnitIdentifier,
    StatusButtons,
      BOLO,
      Status
  },
  data(){
    return {
      statusButtons: null,
    }
  },
  computed: {
    unit() {
      return this.$store.state.auth.leoUnit;
    }
  },
  created() {

    DataRepository.getUnitStatus()
        .then((response) => {
          this.statusButtons=response.data.data;
          this.loading=false;
        });

  }

};
</script>

<style scoped>
.bs-linebreak {
  height:1px;
}
</style>